import React, { useEffect } from 'react';
import styled from 'styled-components';
import "./sponsers.css"
import axis from './images/axis_bank.png';
import gfg from './images/gfg.png';
import instacks from './images/instacks.png';
import ccc from './images/ccc.png';
import newleaf from './images/new-leaf.png';
import shtc from './images/shtc.png';
import matrix from './images/matrix.png';



const Container = styled.div`
  .prize-card {
    
    transform: translateY(20px);
    transition: all 0.3s ease-in-out;
  }
  .sponsor {
    
    transform: scale(0.8);
    transition: all 0.2s ease-in-out;
    &:hover {
      transform: scale(1.1) rotate(5deg);
    }
    &:active {
      transform: scale(0.9);
    }
  }
  @keyframes float {
    0% { transform: translateY(0px); }
    50% { transform: translateY(-10px); }
    100% { transform: translateY(0px); }
  }
`;

export default function Sponsors() {
  useEffect(() => {
    const handleDOMContentLoaded = () => {
      const cards = document.querySelectorAll('.prize-card');
      const sponsors = document.querySelectorAll('.sponsor');

      // Prize cards entrance animation
      cards.forEach((card, index) => {
        setTimeout(() => {
          card.style.opacity = '1';
          card.style.transform = 'translateY(0)';
        }, 300 * index);
      });

      // Sponsor logos entrance animation
      sponsors.forEach((sponsor, index) => {
        setTimeout(() => {
          sponsor.style.opacity = '1';
          sponsor.style.transform = 'scale(1)';
        }, 200 * index);
      });

      // Floating animation for sponsor logos
      sponsors.forEach((sponsor, index) => {
        sponsor.style.animation = `float 3s ease-in-out ${index * 0.5}s infinite`;
      });

      // Sponsor logo click animation
      sponsors.forEach(sponsor => {
        sponsor.addEventListener('click', () => {
          sponsor.style.transform = 'scale(0.9)';
          setTimeout(() => {
            sponsor.style.transform = 'scale(1.1)';
          }, 150);
          setTimeout(() => {
            sponsor.style.transform = 'scale(1)';
          }, 300);
        });
      });
    };

    document.addEventListener('DOMContentLoaded', handleDOMContentLoaded);
    return () => {
      document.removeEventListener('DOMContentLoaded', handleDOMContentLoaded);
    };
  }, []);

  return (
    <Container>
      <div className="container">
        <h1>PRIZE POOL</h1>
        <div className="prize-container">
        <div className="prize-card" id="first">
                <h2>1st Prize</h2>
                <div className="prize-icon">🏆</div>
                <p className="prize-amount">₹1,25,000</p>
                <p className="prize-description">Golden Harvest Award</p>
                <p className="prize-details">Recognizing outstanding achievement in agricultural innovation.</p>
            </div>
            <div className="prize-card" id="second">
                <h2>2nd Prize</h2>
                <div className="prize-icon">🌾</div>
                <p className="prize-amount">₹1,00,000</p>
                <p className="prize-description">Silver Silo Award</p>
                <p className="prize-details">Celebrating significant contributions to sustainable farming practices.</p>
            </div>
            <div className="prize-card" id="third">
                <h2>3rd Prize</h2>
                <div className="prize-icon">🚜</div>
                <p className="prize-amount">₹75,000</p>
                <p className="prize-description">Bronze Barn Award</p>
                <p className="prize-details">Recognizing notable advancements in agricultural technology and efficiency.</p>
            </div>

        </div>

        <h1>Our Sponsors</h1>
        <div className="sponsor-container">
          <div className="sponsor" data-name="Geeks for Geeks">
            <img src={gfg} alt="avishkar2024-Geeks for Geeks" />
          </div>
          <div className="sponsor" data-name="axis-bank">
            <img src={axis} alt="avishkaar 2024-axis-bank" />
          </div>
          <div className="sponsor" data-name="instackas">
            <img src={instacks} alt="avishkaar 2024-instaks" />
          </div>
          <div className="sponsor" data-name="CCC">
            <img src={ccc} alt="avishkaar 2024-CCC" />
          </div>
          <div className="sponsor" data-name="New Leaf">
            <img src={newleaf} alt="avishkaar 2024-newleaf" />
          </div>
          <div className="sponsor" data-name="SHTC">
            <img src={shtc} alt="avishkaar 2024-SHTC" />
          </div>
          <div className="sponsor" data-name="MATRIX">
            <img src={matrix} alt="avishkaar 2024-MATRIX" />
          </div>
        </div>
      </div>
    </Container>
  );
}
