import React from 'react';
import './Fourth.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedinIn, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons'; 
import c from './images/C.jpg';
import coconvener from './images/CC.jpg';


const expertiseTeam = [
    {
        name: "M Sai Srinivas",
        role: "Student Convener",
        phone:"+91 83417 73723",
        avatar: c
    },
    {
        name: "Sravya Sontyana",
        role: "Student CO-Convener",
        phone:"+91 73868 68815",
        avatar: coconvener
    },
    
];

const ExpertProfile = ({ expert }) => (
    <div className="expert-card1">
        <div className="avatar-wrapper1">
            <img className="expert-avatar1" src={expert.avatar} alt={expert.name} />
        </div>
        <div className="expert-info1">
            <h2 className="expert-name1">{expert.name}</h2>
            <h3 >{expert.role}</h3>
            <h3>{expert.phone}</h3>
        </div>
    </div>
);

export default function Fourth() {
    return (
        <>
            <h1 className="team-heading1">Organizing Team</h1>
            <main className="expert-grid1">
                {expertiseTeam.map((expert, index) => (
                    <ExpertProfile key={index} expert={expert} />
                ))}
            </main>
        </>
    );
}
